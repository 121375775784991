// custom typefaces
import "@fontsource-variable/montserrat"
import "@fontsource/merriweather"

// normalize CSS across browsers
// import "./src/normalize.css"

// custom CSS styles
// import "./src/style.css"

import "bootstrap/dist/css/bootstrap.min.css"

import "./static/styles/index.css"
import "./static/styles/override-color.css"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import React from 'react';
import RootWrapper from "./src/components/RootWrapper"

export const wrapPageElement = ({ element, props }) => {
  return <RootWrapper {...props}>{element}</RootWrapper>;
};